import "office-ui-fabric-react/dist/css/fabric.min.css";
import QueryString from "query-string";
import { AppContainer } from "react-hot-loader";
import { initializeIcons } from "office-ui-fabric-react/lib/Icons";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { IStackProps, Stack } from "office-ui-fabric-react/lib/Stack";
import { Label } from "office-ui-fabric-react/lib/Label";
import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./Components/App";

/* global AppCpntainer, Component, document, Office, module, React, require */

initializeIcons();
console.log("here");
let isOfficeInitialized = false;

const title = "Email Filer - Send";

const render = Component => {
  console.log(document.location.href);
  let params = QueryString.parseUrl(document.location.href);

  ReactDOM.render(
    <Component
      projects={params.query["projects[]"]}
      title={title}
      isOfficeInitialized={isOfficeInitialized}
      office={Office.context}
    />,
    document.getElementById("container")
  );
};

/* Render application after Office initializes */
Office.initialize = () => {
  console.log("Office.initialize");
  debugger;
  console.log(Office.context);
  isOfficeInitialized = true;
  render(App);
};

/* Initial render showing a progress bar */
render(
  <Stack>
    <Label>Loading</Label>
    <Spinner size={SpinnerSize.small} />
  </Stack>
);

if (module.hot) {
  module.hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
