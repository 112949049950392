import { Button, ButtonType } from "office-ui-fabric-react";
import "office-ui-fabric-react/dist/css/fabric.min.css";
import React from "react";
import {
  ComboBox,
  Fabric,
  IComboBox,
  IComboBoxOption,
  IComboBoxProps,
  mergeStyles,
  PrimaryButton,
  SelectableOptionMenuItemType
} from "office-ui-fabric-react/lib/index";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Label } from "office-ui-fabric-react/lib/Label";
import { IStackProps, Stack } from "office-ui-fabric-react/lib/Stack";

export default function App(props) {
  const rowProps = { horizontal: true, verticalAlign: "center", childrenGap: 20 };

  return (
    <div style={{ height: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "center" }}>
        <Stack {...rowProps}>
          <Spinner size={SpinnerSize.large} />
          <Label className="ms-fontSize-24"> &nbsp;&nbsp;Filing emails to Atvero</Label>
        </Stack>
      </div>
    </div>
  );
}
